<template>
  <div class="bind_otp">
    <!-- <div class="bind_otp_header">
      <img class="bind_otp_header_back" src="@/assets/images/login/ic_common_back.webp" @click="back" />
      <div class="bind_otp_header_title">{{title}}</div>
    </div> -->
    <div class="divider" />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'BindOTPLayout',
  computed: {
    title() {
      return this.$route.meta.name
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    }
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>
.bind_otp {
  &_header {
    padding: 1em;
    display: flex;
    align-items: center;
    &_back {
      width: 1.5em;
      height: auto;
    }
    &_title {
      flex: auto 1 0;
      font-size: 1.3rem;
      font-weight: 700;
    }
  }
}
</style>